@import "main.css";
.h1, .h2, .h3, .h5, .h6, h1, h2, h3, h5, h6 {
    margin-top: 0;
    margin-bottom: 0 !important;
}

.anchors { top: 0vh !important; }

.websitePageTitle-div {
display: none !important;
}

div:has(> #myGallery) {
padding: 100px 0 !important;
}



/* ---------------------------------------------------------------FOND DU SITE*/

.div-counter {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FMkS9pgAkJDXXikDGzyxO5PHekWB3%2Fimages%2FChristophe_Le_Potier-3P9A7523-A-X3_3spt.webp);
background-size: cover;
background-position: center;
}

/* ---------------------------------------------------------------Bloc références*/

.servicesGallery-card .card-body:hover, .servicesGallery-card-header > h3 > span:hover {
  cursor: default !important;
  pointer-events: none;
}

.servicesGallery-card .card-body {
  min-height: 15vh !important;
}

div.servicesGallery-div {
margin-top: 0 !important;
margin-bottom: 0 !important;
padding-bottom: 100px !important;
}


.servicesGallery-div .card-body {
background-size: contain !important;
}

.servicesGallery-div .grow:hover {
    transform: none !important;
}

.servicesGallery-div h3 {
font-size: 15px !important;
}

/* ---------------------------------------------------------------Bandeau icones*/

.div-counter a {
color: white !important;
    font-size:24px !important;
}

.div-counter span {
font-size: 20px !important;
text-shadow: 4px 4px 14px rgba(0,0,0,0.90);
font-weight: 600;
}

.div-counter a:hover {
color: white !important;
font-weight: 700 !important;
}

.div-counter img {
box-shadow: rgba(46, 116, 88, 0.4) -5px 5px, rgba(46, 116, 88, 0.3) -10px 10px, rgba(46, 116, 88, 0.2) -15px 15px, rgba(46, 116, 88, 0.1) -20px 20px, rgba(46, 116, 88, 0.05) -25px 25px !important;
}

.counter-container {
    flex: 1 0 auto;
    max-width: 300px;
backdrop-filter: brightness(0.7);
padding: 30px;
}

.div-counter {
    flex-wrap: wrap;
    gap: 10px;
}

/* ---------------------------------------------------------------Formulaire de contact*/

#contact .background-image-div-opacity {
background-attachment: fixed;
}

#contact h2 {
margin-top: 30px !important;
margin-bottom: 30px !important;
}

/* ---------------------------------------------------------------Bloc FB*/

div.socialsMiroir-div {
padding-top: 100px !important;
padding-bottom: 100px !important;
}

/* ---------------------------------------------------------------Bloc insta*/

.instagramMiroir-div {
    padding-top: 100px;
    padding-bottom: 100px;
}

/* ---------------------------------------------------------------Bloc avis*/

div.googleReviews-div {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FMkS9pgAkJDXXikDGzyxO5PHekWB3%2Fimages%2FChristophe_Le_Potier-3P9A7523-A-X3_3spt.webp);
background-size: cover;
background-position: center;
margin-bottom: 0 !important;
padding-bottom: 100px;
}

.googleReviews-div .container {
margin-top: 0 !important;
padding-top: 100px;
}

body > main > div.wrapper > div > div.mb-5.googleReviews-div > div.container.m-4.mx-auto > div {
background-color: transparent;
}

body > main > div.wrapper > div > div.mb-5.googleReviews-div > div.container.m-4.mx-auto > div > div > div.d-lg-flex.align-items-lg-center > div {
color: white;
}


/* ---------------------------------------------------------------Blocs texte + 1 image à côté*/
div.text-color-primary {
    margin: 0 !important;
    width: 100%;
}

.text-color-primary img {
border: solid 4px #bec5ad;
}


div.shadow-lg {
    box-shadow: none !important;
}

.text-color-primary h4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}


/* ---------------------------------------------------------------Bloc SEO*/

div.first-activity-div {
margin-top: 150px !important;
}

div.third-activity-div {
margin-bottom: 150px !important;
}



/* ---------------------------------------------------------------Barre de nav*/

a.button_header {
    border-radius: unset !important;
    background: rgba(46, 116, 88, 0.45) !important;
    border: solid 0.7px white !important;
transition: background 0.3s ease, border 0.3s ease; /* Ajout de la transition */
font-size: 20px;
}

a.button_header:hover {
    background: rgba(46, 116, 88, 0.45) !important;
    border: 7px double white !important;
}

a.navbar-brand {
    width: 10vw !important;
    height: 10vh !important;
}

.logo_nav {
    height: 10vh !important;
}

.navbar .dropdown {
display: none !important;
}

/* ---------------------------------------------------------------Bannière vidéo*/

.video-background-holder {  position: relative;  overflow: hidden;  width: 100%;  height: 105vh; /* Full viewport height */}.video-background-holder video {  position: absolute;  top: 50%;  left: 50%;  width: 100%;  height: 100%;  object-fit: cover;  transform: translate(-50%, -50%);}

a.button_homepageScreen {
    border-radius: unset !important;
    background: rgba(46, 116, 88, 0.45) !important;
    border: solid 0.7px white !important;
transition: background 0.3s ease, border 0.3s ease; /* Ajout de la transition */
font-size: 20px;
}

a.button_homepageScreen:hover {
    background: rgba(46, 116, 88, 0.45) !important;
    border: 7px double white !important;
}

.videoTitle span {
padding: 5px;
}

.videoTitle h1 {
margin: 30px 0 !important;
}

.video-background-overlay {
opacity: 0.5 !important;
}

/* ---------------------------------------------------------------Galerie images accueil */

div.redirects-title-background {
border-radius: unset !important;
    background: rgba(46, 116, 88, 0.75) !important;
    border: solid 0.7px white !important;
transition: background 0.3s ease, border 0.3s ease; /* Ajout de la transition */
font-size: 20px;
}

div.redirects-title-background:hover {
background: rgba(46, 116, 88, 0.55) !important;
    border: 7px double white !important;
}

@media all and (min-width: 990px) {
div.row-cols-1 >div.col {
width : 33.3333333333% !important;
}
}

div.redirectsCards-div {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
padding: 110px 0;
}

div.card-content > div {
background-size: cover;
}

.redirects-cards-links {
text-decoration: none !important;
}

/* ---------------------------------------------------------------Cartes photo*/
div.cardsLinks-div {
margin: 0 !important;
padding: 150px 0 !important;
}

div.cardLinks-card {
border-radius: unset !important;
}

div.card-content h2 {
margin-top: 20px !important;
}

.cardLinks-card a, .cardLinks-card a:hover, .cardLinks-card a:visited {
    color: #307458 !important;
}

/* ---------------------------------------------------------------Bloc maps*/

@media (min-width: 1200px) {
    .maps-section .text-color-primary {
        flex: 0 0 auto;
        width: 33.33333333% !important;
    }
}

.maps-section .item-content {
color: white;
}

.maps-section .background-image-div-opacity {
    background-size: cover;
    background-position: center;
background-attachment: fixed;
}

/* ---------------------------------------------------------------Logo*/


#bannertext h1 {
font-size: 1.2rem !important;
}

@media screen and (min-width: 768px)
{
	#bannertext img
	{
		width: 500px;
	}
}

@media screen and (max-width: 767px)
{
	#bannertext img
	{
		width: 300px;
	}
}